import React from "react"
import HomeLayout from "../layout/homeLayout"
import AllCategories from "../templates/allCategories"
import SEO from "../components/seo"
import Ad from "../components/ad"

export default function Home() {
  return (
    <HomeLayout>
      <SEO title="Home All HTML 5 Game Categories | Gangs of Gamer Free online HTML 5 games" />
      {/* <Ad /> */}

      <AllCategories />
      {/* <FeaturedGameCategories /> */}
      {/* <div>
        <a href="/gme_cnt2/game/index.html">Play Game now</a>
        <a href="/love.html">Love now</a>
      </div> .
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          id="div-gpt-ad-1680960708293-0"
          style={{ minWidth: "300px", minHeight: "250" }}
        >
          <script
            dangerouslySetInnerHTML={{
              __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1680960708293-0'); });`,
            }}
          ></script>
        </div>
          </div>*/}
    </HomeLayout>
  )
}
